import './styles/main.scss';

import 'instant.page';

import setupStickyHeader from './js/sticky-header';
import setupAppScreensSlider from './js/app-screens-slider';
import setupOurVetsSlider from './js/our-vets-slider';
import setupMobileNav from './js/mobile-nav';

document.addEventListener( 'DOMContentLoaded', function() {
	setupStickyHeader();
	setupOurVetsSlider();
	setupAppScreensSlider();
	setupMobileNav();
} );

window.addEventListener( 'resize', function() {
	setupOurVetsSlider();
} );
