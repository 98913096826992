export default function() {
  let isSticked = false;
  const $window = window;
  const $header = document.querySelector(".main-header");

  if (!$header) return;

  function stickHeader() {
    if (isSticked) { return }
    $header.classList.add("is-sticky");
    isSticked = true;
  }

  function unstickHeader() {
    if (!isSticked) { return }
    $header.classList.remove("is-sticky");
    isSticked = false;
  }

  const checkForScroll = () => {
    if (document.body.getBoundingClientRect().top < -30) {
      stickHeader();
    } else {
      unstickHeader();
    }
  }

  window.addEventListener("scroll", function () {
    checkForScroll();
  });
}
