import Swiper, { Pagination } from 'swiper';

Swiper.use( [ Pagination ] );

export default function() {
	if ( ! document.querySelector( '.js-app-screens-slider' ) ) {
		return;
	}

	const swiper = new Swiper( '.js-app-screens-slider', {
		slidesPerView: 1,
		spaceBetween: 15,
		pagination: {
			el: '.swiper-pagination',
		},
	} );
}
