import Swiper, { Pagination } from 'swiper';

Swiper.use( [ Pagination ] );

let init = false;
let swiper = Swiper;

export default function() {
	if ( ! document.querySelector( '.js-our-vets-slider' ) ) {
		return;
	}

	if ( window.innerWidth <= 767 ) {
		if ( ! init ) {
			init = true;
			swiper = new Swiper( '.js-our-vets-slider', {
				slidesPerView: 'auto',
				centeredSlides: true,
				spaceBetween: 24,
				pagination: {
					el: '.swiper-pagination',
				},
			} );
		}
	} else if ( init ) {
		swiper.destroy();
		init = false;
	}
}

